import { render, staticRenderFns } from "./Recipes.vue?vue&type=template&id=3124ca2d&scoped=true&"
import script from "./Recipes.vue?vue&type=script&lang=js&"
export * from "./Recipes.vue?vue&type=script&lang=js&"
import style0 from "./Recipes.vue?vue&type=style&index=0&id=3124ca2d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3124ca2d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VRow})
