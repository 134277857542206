<template>
  <div>
    <div style="background-color: #f7f4eb">
      <v-container>
        <v-row justify="center" class="recipes-banner">
          <v-col cols="12" sm="10" lg="6" class="text-center">
            <h1 class="titles-app markazi semibold title-recipes">Recipes</h1>
            <p class="text-descrption">
              Together we’ll make the food you want to see in the world—seeking
              inspiration, creating dynamic new flavors, and above all sourcing
              the freshest, only natural ingredients.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- :fluid="$vuetify.breakpoint.lgAndDown ? true : false" -->
    <v-container class="mt-12 pt-3 pt-xl-5 container-content">
      <v-row no-gutters justify="center" justify-sm="start">
        <v-col
          cols="auto"
          sm="6"
          md="4"
          v-for="(recipe, r) in recipes"
          v-bind:key="'recipe-' + r"
          class="recipe-item"
        >
          <div class="recipe-item-inside">
            <router-link :to="'/recipe/' + recipe.url">
              <v-img
                v-if="recipe.cover !== null"
                v-bind:src="'https://lakewaycms.actstudio.xyz' + recipe.cover.path"
                aspect-ratio="1"
                eager
                class="img-recipes"
              ></v-img>
            </router-link>

            <div class="texts-recipe">
              <router-link
                :to="'/recipe/' + recipe.url"
                class="marzaki bold black--text d-block subtitles-a name-recipe"
                style="text-decoration: none"
              >
                {{ recipe.name }}
              </router-link>

              <span class="marzaki d-block">{{ recipe.category.name }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Recipes",
  data() {
    return {
      recipes: [],
    };
  },
  async beforeMount() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }

    var res = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/Recipes?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: { published: true },
          populate: 1, // resolve linked collection items
          sort: { _created: -1 },
        }),
      }
    ).then((res) => res.json());
    // .then((res) => (this.recipes = res.entries));

    this.recipes = res.entries;
  },
};
</script>

<style lang="scss" scoped>
$xxl: 3629px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.title-recipes {
  margin-bottom: 15px;
}

.text-descrption {
  max-width: 607px;
  margin: auto;

  @media screen and(max-width: $xl) {
    max-width: 476px;
  }

  @media screen and(max-width: $lg) {
    max-width: 505px;
  }

  @media screen and(max-width: $sm) {
    max-width: 305px;
  }
}

.recipes-banner {
  padding: 60px 0;

  @media screen and(max-width: $xl) {
    padding: 51px 0;
  }
}

.img-recipes {
  // width: 450px;
  height: 450px;

  @media screen and(max-width: $xl) {
    width: 100%;
    height: 320px;
  }

  @media screen and(max-width: $lg) {
    width: 322px;
  }

  @media screen and(max-width: $sm) {
    width: 300px;
  }
}

.recipe-item {
  padding: 32px 37px;

  @media screen and(max-width: $xl) {
    padding: 20px 21px;
  }

  .recipe-item-inside {
    padding: 0 23px;

    @media screen and(max-width: $xl) {
      padding: 0;
    }
  }
}

.name-recipe {
  display: block;
  margin-top: 20px;
}

.container-content {
  padding-bottom: 130px;
}

.texts-recipe {
  @media screen and(max-width: $lg) {
    max-width: 322px;
  }
}
</style>